import { distinct as distinctFilter } from "@vatsim-vnas/js-libs/utils";
import { FormikProps, getIn } from "formik";
import React, { ChangeEvent, FocusEvent, useEffect, useId, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import * as S from "src/styles/ui";

export interface CommaListInputProps<F> {
  allowDuplicates?: boolean;
  allowSpaces?: boolean;
  formik?: FormikProps<F>;
  label?: string;
  name?: string;
  onChange?: (value: string[]) => void;
  placeholder: string;
  sort?: boolean;
  tableCell?: boolean;
}

function CommaListInput<F>({
  allowDuplicates,
  allowSpaces,
  formik,
  label,
  name,
  onChange,
  placeholder,
  sort,
  tableCell,
}: Readonly<CommaListInputProps<F>>) {
  const useFormik = formik && name;
  const [displayValue, setDisplayValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [cursor, setCursor] = useState<number | null>(null);
  const id = useId();
  const ref = useRef<HTMLInputElement>(undefined!);

  useEffect(() => {
    ref.current?.setSelectionRange(cursor, cursor);
  }, [ref, cursor, useFormik && getIn(formik.values, name)]);

  useEffect(() => {
    if (!focused && useFormik) {
      setDisplayValue(getIn(formik.values, name).join(", "));
    }
  }, [formik]);

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFocused(false);
    if (useFormik) {
      formik.handleBlur(e);
    }
  };

  const handleChange = (value: string) => {
    let finalValue = value.toUpperCase();
    setDisplayValue(finalValue);
    finalValue = finalValue.trim();
    let values = allowSpaces ? finalValue.split(/,\s*/) : finalValue.split(/[ ,]+/);
    values = values.filter((v) => v);
    if (sort) {
      values.sort();
    }
    if (!allowDuplicates) {
      values = values.filter(distinctFilter);
    }
    if (useFormik) {
      setCursor(ref.current.selectionStart);
      formik.setFieldValue(name, values);
    }
    if (onChange) {
      onChange(values);
    }
  };

  return (
    <Form.Group>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      {tableCell ? (
        <S.FormControlTableCell
          autoComplete="off"
          isInvalid={useFormik ? getIn(formik.errors, name) && getIn(formik.touched, name) : false}
          id={id}
          name={name}
          onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
          ref={ref}
          value={displayValue}
        />
      ) : (
        <Form.Control
          autoComplete="off"
          isInvalid={useFormik ? getIn(formik.errors, name) && getIn(formik.touched, name) : false}
          id={id}
          name={name}
          onBlur={(e) => handleBlur(e as ChangeEvent<HTMLInputElement>)}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
          ref={ref}
          value={displayValue}
        />
      )}

      {useFormik && <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>}
    </Form.Group>
  );
}

CommaListInput.defaultProps = {
  allowDuplicates: false,
  allowSpaces: false,
  formik: undefined,
  label: undefined,
  name: undefined,
  onChange: undefined,
  sort: true,
  tableCell: false,
};

export default CommaListInput;
